(function( $, window, document, undefined ) {

	'use strict';

	$(
		function() {

			$( '.js-menu-toggle' ).on(
				'click', function( event ) {
					event.preventDefault();

					$( '.js-menu' ).toggleClass( 'is-active' );
					$( this ).toggleClass( 'is-active' );
				}
			);

			$( '.js-slider' ).slick( {
				dots: false,
				arrows: true,
				infinite: true,
				rows: 0
			} );

			$( window ).scroll( function() {
				if ( $( this ).scrollTop() > 30 ) {
					$( '.header' ).addClass( 'header--fixed' );
				} else {
					$( '.header' ).removeClass( 'header--fixed' );
				}
			} );

		}
	);

})( jQuery, window, document );
